import TYPES from '@/types';

// Application
import GetCustomerKuspitPositionQuery from '@/modules/my-investment/kuspit/customer-kuspit-position/application/queries/get-customer-kuspit-position-query';
import GetCustomerInvestorGoalBalanceQuery
  from '@/modules/flagship/customer-investor-goal-balance/application/query/get-customer-investor-goal-balance';
import GetSearchByCustomerQuery
  from '@/modules/flagship/investor-goal/search-by-customer/application/queries/get-search-by-customer-query';

// Domain
import {
  InvestorGoalsDto,
} from '@/modules/flagship/investor-goal/link-sowos-pocket/domain/dtos/investor-goals-dto';
import {
  CustomerInvestorGoalBalanceQueryEntity,
} from '@/modules/flagship/customer-investor-goal-balance/domain/entities/customer-investor-goal-balance-query-entity';
import { SearchByCustomerDto } from '@/modules/flagship/investor-goal/search-by-customer/domain/dtos/search-by-customer-dto';
import Inject from '@/modules/shared/domain/di/inject';
import Translator from '@/modules/shared/domain/i18n/translator';
import { Values } from '@/modules/shared/domain/i18n/types';
import { MessageNotifier } from '@/modules/shared/domain/notifiers/message_notifier';

export default class FlagshipLinkGoalsWizardLinkPocketViewModel {
  @Inject(TYPES.GET_SEARCH_BY_CUSTOMER_QUERY)
  private readonly get_search_by_customer_query!: GetSearchByCustomerQuery;

  @Inject(TYPES.GET_CUSTOMER_KUSPIT_POSITION_QUERY)
  private readonly get_customer_kuspit_position_query!: GetCustomerKuspitPositionQuery;

  @Inject(TYPES.GET_CUSTOMER_INVESTOR_GOAL_BALANCE_QUERY)
  private readonly get_customer_investor_goal_balance_query!: GetCustomerInvestorGoalBalanceQuery;

  @Inject(TYPES.NOTIFIER)
  private readonly message_notifier!: MessageNotifier;

  @Inject(TYPES.I18N)
  readonly translator!: Translator;

  readonly i18n_namespace = 'components.flagship.flagship-link-goals';

  is_loading = false;

  show_step_success_pocket = false;

  goals_selected = [];

  investor_goals: InvestorGoalsDto = {
    total_active_goals_balance: '0',
    unassigned_balance: '0',
    investor_goals: [],
  };

  private STEPS = [
    'StepChooseGoalsPocket',
    'StepDistributeBalancePocket',
    'StepScheduleReminderPocket',
  ];

  current_step = 0;

  has_active_goals = false;

  balance = 0;

  current_component = this.STEPS[this.current_step];

  private customer_investor_goal_balance: CustomerInvestorGoalBalanceQueryEntity = {
    investment_product_id: '',
    reload: true,
  };

  private search_by_customer_dto: SearchByCustomerDto = {
    reload: true,
    associated_product_id: '',
    is_active: true,
  };

  user_has_balance = false;

  translate = (message: string, values?: Values) => this.translator.translate(`${this.i18n_namespace}.${message}`, values);

  menu_steps = this.translate('pocket.steps_link_pocket_with_balance');

  initialize = async (associated_product_id: string) => {
    if (associated_product_id) {
      this.customer_investor_goal_balance.investment_product_id = associated_product_id;
      this.search_by_customer_dto.associated_product_id = associated_product_id;
      await this.loadActiveGoals();
      if (this.has_active_goals) {
        await this.loadCustomerInvestorGoalBalance();
      } else {
        await this.loadKuspitPosition();
      }
      this.setCorrectMenuSteps();
    }
  }

  loadActiveGoals = async () => {
    try {
      // eslint-disable-next-line max-len
      const active_goals = await this.get_search_by_customer_query.execute(this.search_by_customer_dto);
      if (active_goals.length) {
        this.has_active_goals = true;
      }
    } catch {
      this.message_notifier.showErrorNotification(this.translate('pocket.errors.load_active_goals'));
    }
  }

  loadCustomerInvestorGoalBalance = async () => {
    try {
      // eslint-disable-next-line max-len
      const { unassigned_balance } = await this.get_customer_investor_goal_balance_query.execute(this.customer_investor_goal_balance);
      this.balance = unassigned_balance;
    } catch (error) {
      if (JSON.parse(error).status_code !== 404) {
        this.message_notifier.showErrorNotification(this.translate('pocket.errors.get_customer_investor_goal_balance'));
      }
    }
  }

  loadKuspitPosition = async () => {
    try {
      const kuspit_position_data = await this.get_customer_kuspit_position_query.execute();
      if (kuspit_position_data.has_funds) {
        this.balance = Number(kuspit_position_data.position);
      }
    } catch {
      this.message_notifier.showErrorNotification(this.translate('pocket.errors.get_kuspit_position'));
    }
  }

  setCorrectMenuSteps = () => {
    if (this.balance > 0) {
      this.user_has_balance = true;
      this.STEPS = [
        'StepChooseGoalsPocket',
        'StepDistributeBalancePocket',
        'StepScheduleReminderPocket',
      ];
      this.menu_steps = this.translate('pocket.steps_link_pocket_with_balance');
    } else {
      this.STEPS = [
        'StepChooseGoalsPocket',
        'StepScheduleReminderPocket',
      ];
      this.menu_steps = this.translate('pocket.steps_link_pocket_without_balance');
    }
  }

  nextStep = () => {
    if (this.current_step < this.STEPS.length) {
      this.current_step += 1;
      this.current_component = this.STEPS[this.current_step];
    }
  }

  prevStep = () => {
    if (this.current_step > 0) {
      this.current_step -= 1;
      this.current_component = this.STEPS[this.current_step];
    }
  }

  endProcess= () => {
    this.current_step = 0;
    this.current_component = this.STEPS[this.current_step];
    this.show_step_success_pocket = true;
  }

  changeLoadingStep = (loading: boolean) => {
    this.is_loading = loading;
  }

  changeGoalsSelected = (goals_selected: []) => {
    this.goals_selected = goals_selected;
  }

  changeInvestorGoals = (investor_goals: InvestorGoalsDto) => {
    this.investor_goals = { ...investor_goals };
  }

  createInvestorGoalsDto = () => {
    if (this.balance <= 0) {
      this.investor_goals.investor_goals.length = 0;
      this.goals_selected.forEach((investor_goal_id: string) => {
        this.investor_goals.investor_goals.push({
          investor_goal_id,
          active_goal_amount: '0',
          recurrent_assigned_amount: '0',
        });
      });
    }
  }
}
